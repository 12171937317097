<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '数据增加' : '数据修改'" :visible.sync="isShow" width="800px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />

        <el-form size="small" :inline="true" label-width="150px">
          <el-form-item label="商户名称:">
            <el-input v-model="m.mchName" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="appid:">
            <el-input v-model="m.appid" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="业务域名:">
            <el-input v-model="m.host" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item v-if="m.type !=3" label="是否使用证书:">
            <el-radio v-model="m.isCert" label="0">证书</el-radio>
            <el-radio v-model="m.isCert" label="1">密钥</el-radio>
          </el-form-item>
          <br />
          <el-form-item label="应用私钥:">
            <el-input :rows="5" type="textarea" v-model="m.privateKey" style="width: 500px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="应用公钥证书:">
            <el-input :rows="5" type="textarea" v-model="m.appPublicCertPath" style="width: 500px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="支付宝公钥证书:">
            <el-input :rows="5" type="textarea" v-model="m.alipayPublicCertPath" style="width: 500px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="支付宝根证书:">
            <el-input :rows="5" type="textarea" v-model="m.alipayRootCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <br />
          <el-form-item label="限额:">
            <el-input v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>

          <br />
          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="备注:">
            <el-input type="text" v-model="m.remarks" style="width: 350px"></el-input>
            <br />
          </el-form-item>
          <br />
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      zftlist: [],
      m: {
        appid: "", // 从菜单配置文件里传递过来的参数
        host: "",
        limits: 5000,
        status: 0,
        remarks: "",
        mchName: "",
        privateKey: "",
        alipayUid: "",
        appPublicCertPath: "",
        alipayPublicCertPath: "",
        alipayRootCertPath: "",
        type: "2",
        isCert: "0",
        payType: "7",
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.appid = data.appid;
        this.m.host = data.host;
        this.m.limits = data.limits;
        this.m.status = data.status;
        this.m.remarks = data.remarks;
        this.m.mchName = data.mchName;
        this.m.privateKey = data.privateKey;
        this.m.appPublicCertPath = data.appPublicCertPath;
        this.m.alipayPublicCertPath = data.alipayPublicCertPath;
        this.m.alipayRootCertPath = data.alipayRootCertPath;
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        this.sa.ajax(
          "/v1/alipay/config/save",
          this.m,
          function () {
            self.sa.alert("添加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              self.claerData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/v1/alipay/config/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      Object.keys(this.m).forEach((key) => {
        this.m[key] = "";
      });
      this.m.type = "2";
      this.m.isCert = "0";
      this.m.payType = "7";
    },
  },
  created: function () {
  },
};
</script>
